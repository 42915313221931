// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-beauty-js": () => import("./../../../src/pages/About-Beauty.js" /* webpackChunkName: "component---src-pages-about-beauty-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-borgfeld-as-an-archisculpture-js": () => import("./../../../src/pages/Borgfeld-as-an-archisculpture.js" /* webpackChunkName: "component---src-pages-borgfeld-as-an-archisculpture-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-het-sociale-spel-js": () => import("./../../../src/pages/Het-Sociale-Spel.js" /* webpackChunkName: "component---src-pages-het-sociale-spel-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-keep-yourself-warm-and-burn-it-down-js": () => import("./../../../src/pages/Keep-Yourself-Warm-And-Burn-It-Down.js" /* webpackChunkName: "component---src-pages-keep-yourself-warm-and-burn-it-down-js" */),
  "component---src-pages-please-dont-js": () => import("./../../../src/pages/Please-Dont.js" /* webpackChunkName: "component---src-pages-please-dont-js" */),
  "component---src-pages-the-dark-galleries-js": () => import("./../../../src/pages/The-Dark-Galleries.js" /* webpackChunkName: "component---src-pages-the-dark-galleries-js" */),
  "component---src-pages-tunneltaal-js": () => import("./../../../src/pages/Tunneltaal.js" /* webpackChunkName: "component---src-pages-tunneltaal-js" */),
  "component---src-pages-un-entretien-avec-un-chat-js": () => import("./../../../src/pages/Un-Entretien-Avec-Un-Chat.js" /* webpackChunkName: "component---src-pages-un-entretien-avec-un-chat-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-workshops-js": () => import("./../../../src/pages/Workshops.js" /* webpackChunkName: "component---src-pages-workshops-js" */)
}

